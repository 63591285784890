import { createStore } from 'vuex'

export default createStore({
  state: {
    // base_url: "http://127.0.0.1:5000",
    base_url: "https://api.check.oceanstellar.com",
    username: 'test',
    permission: 1003,
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
