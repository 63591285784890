<template>

  <el-card >
    <el-select placeholder="请选择" v-model="current_file" @change="fileChange">
      <el-option v-for="item in file_list" :key="item.value" :label="item.label" :value="item.value">
      </el-option>
    </el-select>
    共有{{items.length}}条数据，当前是第{{now_sentence + 1}}条数据
    <el-button @click="pre">上一条</el-button>
    <el-button @click="next">下一条</el-button>
    <el-checkbox v-model="isNeedSign[now_sentence]" style="margin:auto 20px">是否需要打标</el-checkbox>
    <el-button @click="submit" :disabled="items.length - 1 != now_sentence">提交</el-button>
  </el-card>
  <el-card >
    {{sentence}}
  </el-card>

  <el-card>
    <el-button @click="ship2locInsert(-1)" type="primary" size="small" style="float: right">在最上方插入</el-button>
    <el-table :data="act_events" style="width: 100%">
      <el-table-column prop="ship" label="ship">
        <template #default="scope">
          <el-input v-model="scope.row.ship" autocomplete="new-password"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="num" label="num">
        <template #default="scope">
          <el-input v-model="scope.row.num" autocomplete="new-password"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="act" label="act">
        <template #default="scope">
          <el-input v-model="scope.row.act" autocomplete="new-password"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="loc" label="loc">
        <template #default="scope">
          <el-input v-model="scope.row.loc" autocomplete="new-password"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="ori" label="ori" >
        <template #default="scope">
          <el-input v-model="scope.row.ori" autocomplete="new-password"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="des" label="des">
        <template #default="scope">
          <el-input v-model="scope.row.des" autocomplete="new-password"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="time" label="time">
        <template #default="scope">
          <el-input v-model="scope.row.time" autocomplete="new-password"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="for" label="for">
        <template #default="scope">
          <el-input v-model="scope.row.for" readonly onfocus="this.removeAttribute('readonly');"></el-input>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作">
        <template #default="scope">
          <el-button @click="ship2locInsert(scope.$index)" link type="primary" size="small">插入</el-button>
          <el-button @click="ship2locDelete(scope.$index)" link type="primary" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
  <el-card >
    <el-button @click="ship2shipInsert(-1)" type="primary" size="small" style="float: right">在最上方插入</el-button>
    <el-table :data="ship2ship_events" style="width: 100%">
      <el-table-column prop="subject_ship" label="subject_ship">
        <template #default="scope">
          <el-input v-model="scope.row.subject_ship" autoComplete="new-password"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="subject_ship_num" label="subject_ship_num">
        <template #default="scope">
          <el-input v-model="scope.row.subject_ship_num" autoComplete="new-password"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="object_ship" label="object_ship">
        <template #default="scope">
          <el-input v-model="scope.row.object_ship" autoComplete="new-password"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="object_ship_num" label="object_ship_num">
        <template #default="scope">
          <el-input v-model="scope.row.object_ship_num" autoComplete="new-password"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="act" label=" act">
        <template #default="scope">
          <el-input v-model="scope.row.act" autoComplete="new-password"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="time" label="time">
        <template #default="scope">
          <el-input v-model="scope.row.time" readonly onfocus="this.removeAttribute('readonly');"></el-input>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作">
        <template #default="scope">
          <el-button @click="ship2shipInsert(scope.$index)" link type="primary" size="small">插入</el-button>
          <el-button @click="ship2shipDelete(scope.$index)" link type="primary" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-card>

</template>

<script>


import axios from "axios";
import store from "@/store"
const base_url = store.state.base_url
export default {
  name: "Test",
  data() {
    return {
      name: 'Hello Vue!',
      current_file: "",
      file_list: [],
      items: [],
      sentence: "",
      isNeedSign: [],
      now_sentence: 0,
      act_events: [],
      ship2ship_events: [],
      username: "",
      password: ""
    }
  },
  mounted() {
    let that = this
    let token = sessionStorage.getItem("token")

    if (token) {
      axios.get(base_url + '/output/check/getFileList', { headers: { Authorization: "Bearer " + token } })
          .then(res => {
            //let tmp_array = []
           if(res.data.code == 200){
             res.data.data.forEach(function (item) {
               that.file_list.push({ 'value': item, 'label': item })
             })
             //this.file_list = tmp_array
             if (res.data.data.length > 0) {
               this.current_file = res.data.data[0]
             }
             let data = { "filename": this.current_file }
             axios
                 .post(base_url + '/output/check/getdata', data, { headers: { Authorization: "Bearer " + token } })
                 .then(response => {
                   this.items = response.data.data

                   if (this.items.length > 0) {
                     this.act_events = this.items[0].act_events
                     this.now_sentence = 0
                     this.ship2ship_events = this.items[0].ship2ship_events
                     this.sentence = this.items[0].sentence
                   }
                   let tmp_sign = []
                   response.data.data.forEach(function () {
                     tmp_sign.push(false)
                   })
                   this.isNeedSign = tmp_sign
                 })
                 .catch(function (error) { // 请求失败处理

                 });
           }else if(res.data.code == 4001){
             alert("登录超时，请重新登录。")
             sessionStorage.clear()
             this.$router.push({name:'login'})
           }
          })
          .catch((error) => {

          })
    }



  },

  methods: {
    pre(event) {
      if (this.now_sentence > 0) {
        this.now_sentence = this.now_sentence - 1
        this.act_events = this.items[this.now_sentence].act_events
        this.sentence = this.items[this.now_sentence].sentence
        this.ship2ship_events = this.items[this.now_sentence].ship2ship_events
      }
    },
    next (event) {
      if (this.now_sentence < this.items.length - 1) {
        this.now_sentence = this.now_sentence + 1
        this.act_events = this.items[this.now_sentence].act_events
        this.sentence = this.items[this.now_sentence].sentence
        this.ship2ship_events = this.items[this.now_sentence].ship2ship_events
      }
    },
    fileChange(value) {
      let data = { "filename": this.current_file }
      let token = sessionStorage.getItem("token")
      axios
          .post(base_url + '/output/check/getdata', data, { headers: { Authorization: "Bearer " + token } })
          .then(response => {
            if(response.data.code == 200){
              this.items = response.data.data
              if (this.items.length > 0) {
                this.act_events = this.items[0].act_events
                this.now_sentence = 0
                this.ship2ship_events = this.items[0].ship2ship_events
                this.sentence = this.items[0].sentence
              }
              let tmp_sign = []
              response.data.data.forEach(function (item) {
                tmp_sign.push(false)
              })
              this.isNeedSign = tmp_sign
            }else if(response.data.code == 4001){
              alert("登录超时，请重新登录。")
              sessionStorage.clear()
              this.$router.push({name:'login'})
            }
          })
          .catch(function (error) { // 请求失败处理
            // alert("登录超时，请重新登录。")
            // sessionStorage.clear()
            // this.$router.push({name:'login'})
          });
    },
    ship2locInsert(value) {
      let tmp_list = this.act_events
      tmp_list.splice(value + 1, 0, {
        ship: '',
        num: '',
        act: '',
        loc: '',
        ori: '',
        des: '',
        time: '',
        for: ''
      })
      this.act_events = tmp_list

    },
    ship2locDelete(value) {
      //let tmp_list = this.act_events
      //tmp_list.splice(value, 1)
      //this.act_events = tmp_list

      this.$confirm("确定要删除吗？", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        confirmButtonClass: "confirmButton",
        cancelButtonClass: "cancelButton",
        type: "warning"
      })
          .then(() => {
            this.act_events.splice(value, 1)

          })
          .catch(() => {

          });
    },

    ship2shipInsert (value) {
      let tmp_list = this.ship2ship_events
      tmp_list.splice(value + 1, 0, {
        subject_ship: '',
        subject_ship_num: '',
        object_ship: '',
        object_ship_num: '',
        act: '',
        time: '',
      })
      this.ship2ship_events = tmp_list

    },
    ship2shipDelete(value) {
      this.$confirm("确定要删除吗？", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        confirmButtonClass: "confirmButton",
        cancelButtonClass: "cancelButton",
        type: "warning"
      })
          .then(() => {
            this.ship2ship_events.splice(value, 1)

          })
          .catch(() => {

          });
    },
    submit(value) {
      let data = { 'data': [], 'filename': this.current_file }
      let token = sessionStorage.getItem("token")
      for (let i = 0; i < this.items.length; i++) {
        data['data'].push(
            {
              'sentence': this.items[i].sentence,
              'act_events': this.items[i].act_events,
              'ship2ship_events': this.items[i].ship2ship_events,
              'is_need_sign': this.isNeedSign[i]
            }
        )
      }
      axios
          .post(base_url + '/output/check/setdata', data, { headers: { Authorization: "Bearer " + token } })
          .then(
              (res) => {
                if (res.data.code == 200) {
                  alert("保存成功！")
                  axios
                      .post(base_url + '/output/check/getdata', data, { headers: { Authorization: "Bearer " + token } })
                      .then(response => {
                        this.items = response.data.data

                        if (this.items.length > 0) {
                          this.act_events = this.items[0].act_events
                          this.now_sentence = 0
                          this.ship2ship_events = this.items[0].ship2ship_events
                          this.sentence = this.items[0].sentence
                        }
                        let tmp_sign = []
                        response.data.data.forEach(function (item) {
                          tmp_sign.push(false)
                        })
                        this.isNeedSign = tmp_sign
                      })
                      .catch(function (error) { // 请求失败处理
                        console.log(error);
                        this.loginVisible = true
                      });
                }else if(res.data.code == 4001)
                {
                  alert("登录超时，请重新登录。")
                  sessionStorage.clear()
                  this.$router.push({name:'login'})
                }
              }
          ).catch(() => {
        alert("登录超时，请重新登录。")
        sessionStorage.clear()
        this.$router.push({name:'login'})

      })
    },

  }
}
</script>

<style>
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
