<template>

  <el-menu  style="min-height: calc(100vh - 60px);"
            :default-active="route.name" background-color="#8AAA9F"
            active-text-color="#ffd04b" text-color="#fff"
            router
            class="el-menu-vertical"
            :collapse="isCollapse"
            :default-openeds="state.defaultOpen"
  >

    <template v-if="store.state.permission == 1002 || store.state.permission == 1001">
      <el-menu-item index="check">
        <el-icon><icon-menu /></el-icon>
        <span>检查</span>
      </el-menu-item>
    </template>
    <el-menu-item index="label">
      <el-icon><icon-menu /></el-icon>
      <span>打标</span>
    </el-menu-item>


  </el-menu>
</template>

<script>
import {defineComponent, inject, reactive, ref, watch} from 'vue'
import {
  Location,
  Document,
  Menu as IconMenu,
  Setting,
} from '@element-plus/icons-vue'
import {useRoute} from "vue-router";
import {useStore} from "vuex";

export default defineComponent({
  components: {
    Location,
    Document,
    Setting,
    IconMenu,
  },
  setup() {
    const store = useStore()
    const isCollapse = ref(false)
    const route =useRoute()
    const menuList = [
      {
        name: '检查',
        id:'check',

      },
      {
        name:'打标',
        id:'label',

      },
    ]
    const state = reactive({
      defaultOpen: ['devManager','dataCenter'],
    })
    return {
      isCollapse,
      state,
      route,
      menuList,
      store
    }
  },
  methods:{
    clickTransRoute(){
      this.$router.push({name:'devhistorydata'})
    },
  },


})
</script>

<style>
.el-menu-vertical:not(.el-menu--collapse) {
  width: 250px;
  min-height: 400px;
}
.el-menu-vertical .el-sub-menu.is-active {
  background-color: rgb(231, 235, 240) !important;
}
.el-submenu__title:hover{
  background-color:rgb(3, 19, 33) !important;
}
.el-menu-vertical .el-sub-menu__title{
  font-size: 16px;
}
.el-menu-vertical .el-menu-item{
  font-size: 16px;
}



</style>