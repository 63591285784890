import { createRouter, createWebHistory,createWebHashHistory } from 'vue-router'
import LabelHome from "@/views/Label/LabelHome";
import LoginLayout from "@/layout/LoginLayout";
import HomeLayout from "@/layout/HomeLayout";
import OutputCheck from "@/views/Check/OutputCheck"
import Login from "@/views/Login"
const routes = [
  {
    path:'/',
    component: LoginLayout,
    children: [
      {
        path: '/',
        name: 'login',
        component: Login
      },
      {
        path: '/login',
        component: Login
      }
    ]
  },
  {
    path: '/home',
    name: 'home',
    component: HomeLayout,
    children:[
      {
        path:'/label',
        name:'label',
        meta:{
          auth: true,
        },
        component: LabelHome
      },
      {
        path:'/check',
        name:'check',
        meta:{
          auth: true,
        },
        component: OutputCheck
      }

    ]
  }

]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // 1. 每个条件执行后都要跟上 next() 或 使用路由跳转 api 否则页面就会停留一动不动
  // 2. 要合理的搭配条件语句，避免出现路由死循环。
  const token = sessionStorage.getItem('token')
  if (to.meta.auth) {
    if (!token) {
      return router.replace({
        name: 'login'
      })
    }
    next()
  } else {
    next()
  }
})


export default router
