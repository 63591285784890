<template>
<!--  <h1>欧海星海洋信息标签系统</h1>-->
  <el-card>
    <el-select placeholder="请选择" v-model="current_file" @change="fileChange" style="margin-right: 20px;">
      <el-option v-for="item in file_list" :key="item.value" :label="item.label" :value="item.value">
      </el-option>
    </el-select>
    <el-button @click="pre">上一条</el-button>
    <el-button @click="next">下一条</el-button>
    <el-checkbox v-model="isAbandon[now_page]" style="margin: 0 20px;">不保留该句</el-checkbox>
    <el-button type="success" @click="export_file">提交</el-button>
  </el-card>

<!--  <h1>来打标</h1>-->
  <el-card v-if="show_result">
    <div class="card-header">
      <span>{{TextAndWord2Label[now_page].text}}</span>
      <el-button @click="loadNowSentEvent" type="primary">导出事件表</el-button>
    </div>
    <el-table :data="TextAndWord2Label[now_page].word2label" style="width: 100%" max-height="600px" :row-class-name="tableRowClassName">
      <el-table-column  label="单词" width="180" >
        <template #default="scope">
          <el-tag
              effect="dark"
              type="success"
              size="large"
          >
            {{ TextAndWord2Label[now_page].word2label[scope.$index].word }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="BIO">
        <template #default="scope">
          <el-select v-model="TextAndWord2Label[now_page].word2label[scope.$index].bio" placeholder=" ">
            <el-option
                v-for="item in bio_options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                :disabled="item.disabled"
            />
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="label">
        <template #default="scope">
          <el-select v-model="TextAndWord2Label[now_page].word2label[scope.$index].label"
                     :disabled="TextAndWord2Label[now_page].word2label[scope.$index].bio>0"
                     placeholder=" "
                     @visible-change = "autoSetIndex($event,scope.$index)"
          >
            <el-option
                v-for="item in label_options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                :disabled="item.disabled"
            />
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="index">
        <template #default="scope">
          <el-input v-model="TextAndWord2Label[now_page].word2label[scope.$index].index"
                     :disabled="TextAndWord2Label[now_page].word2label[scope.$index].bio>0"
                     placeholder=" "

          >
          </el-input>
        </template>
      </el-table-column>
      <el-table-column label="ship/act">
        <template #default="scope">
          <el-input v-model="TextAndWord2Label[now_page].word2label[scope.$index].ship"
                     :disabled="TextAndWord2Label[now_page].word2label[scope.$index].bio>0
                     || TextAndWord2Label[now_page].word2label[scope.$index].label<6"
                     placeholder=" "
          >
          </el-input>
        </template>
      </el-table-column>
      <el-table-column label="loc/for">
        <template #default="scope">
          <el-input v-model="TextAndWord2Label[now_page].word2label[scope.$index].loc"
                     :disabled="TextAndWord2Label[now_page].word2label[scope.$index].bio>0 ||
                      TextAndWord2Label[now_page].word2label[scope.$index].label<6"
                     placeholder=" ">

          </el-input>
        </template>
      </el-table-column>
      <el-table-column label="time">
        <template #default="scope">
          <el-input v-model="TextAndWord2Label[now_page].word2label[scope.$index].time" placeholder=" "
                     :disabled="TextAndWord2Label[now_page].word2label[scope.$index].bio>0 || TextAndWord2Label[now_page].word2label[scope.$index].label<7">

          </el-input>
        </template>
      </el-table-column>
      <el-table-column label="ori">
        <template #default="scope">
          <el-input v-model="TextAndWord2Label[now_page].word2label[scope.$index].ori" placeholder=" "
                     :disabled="TextAndWord2Label[now_page].word2label[scope.$index].bio>0 || TextAndWord2Label[now_page].word2label[scope.$index].label<7">

          </el-input>
        </template>
      </el-table-column>
      <el-table-column label="des">
        <template #default="scope">
          <el-input v-model="TextAndWord2Label[now_page].word2label[scope.$index].des" placeholder=" "
                     :disabled="TextAndWord2Label[now_page].word2label[scope.$index].bio>0 || TextAndWord2Label[now_page].word2label[scope.$index].label<7">

          </el-input>
        </template>
      </el-table-column>
      <el-table-column label="act">
        <template #default="scope">
          <el-input v-model="TextAndWord2Label[now_page].word2label[scope.$index].act" placeholder=" "
                    :disabled="TextAndWord2Label[now_page].word2label[scope.$index].bio>0 || TextAndWord2Label[now_page].word2label[scope.$index].label!==8">

          </el-input>
        </template>
      </el-table-column>
      <el-table-column label="appeared">
        <template #default="scope">
          <el-checkbox v-model="TextAndWord2Label[now_page].word2label[scope.$index].appeared" size="large" />
        </template>
      </el-table-column>
      <el-table-column label="unk">
        <template #default="scope">
          <el-checkbox v-model="TextAndWord2Label[now_page].word2label[scope.$index].unknown" size="large" />
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination
          background
          :page-size="1"
          layout="prev, pager, next, jumper"
          :total="max_page"
          :current-page="now_page + 1"
          @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </el-card>
  <el-dialog
      v-model="eventDialogVisible"
      title="事件表"
      width="60%">
    <h4>ship 2 loc</h4>
    <el-table :data="event_list[now_page].ship2loc">
      <el-table-column  label="ship">
        <template #default="scope">
          <el-input v-model="event_list[now_page].ship2loc[scope.$index].ship"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="num" label="num"></el-table-column>
      <el-table-column prop="act" label="act"></el-table-column>
      <el-table-column label="loc">
        <template #default="scope">
          <el-input v-model="event_list[now_page].ship2loc[scope.$index].loc"></el-input>
        </template>
      </el-table-column>
      <el-table-column  label="time">
        <template #default="scope">
          <el-input v-model="event_list[now_page].ship2loc[scope.$index].time"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="ori">
        <template #default="scope">
          <el-input v-model="event_list[now_page].ship2loc[scope.$index].ori"></el-input>
        </template>
      </el-table-column>
      <el-table-column  label="des">
        <template #default="scope">
          <el-input v-model="event_list[now_page].ship2loc[scope.$index].des"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="for" label="for"></el-table-column>
    </el-table>
    <h4>ship 2 ship</h4>
    <el-table :data="event_list[now_page].ship2ship">
      <el-table-column  label="subject_ship">
        <template #default="scope">
          <el-input v-model="event_list[now_page].ship2ship[scope.$index].subject_ship"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="subject_ship_num" label="subject_ship_num"></el-table-column>
      <el-table-column label="object_ship">
        <template #default="scope">
          <el-input v-model="event_list[now_page].ship2ship[scope.$index].object_ship"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="object_ship_num" label="object_ship_num"></el-table-column>
      <el-table-column prop="act" label="act"></el-table-column>
      <el-table-column  label="time">
        <template #default="scope">
          <el-input v-model="event_list[now_page].ship2ship[scope.$index].time"></el-input>
        </template>
      </el-table-column>

      <el-table-column prop="ori" label="ori"></el-table-column>
      <el-table-column prop="des" label="des"></el-table-column>
    </el-table>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="getNowSentEvent" type="danger">重新加载</el-button>
        <el-button @click="eventDialogVisible=false" type="success">确认</el-button>
      </span>

    </template>
  </el-dialog>




</template>

<script>

import {ref, onMounted} from "vue";
import axios from "axios";
import {useRouter} from "vue-router";
import {useStore} from "vuex";

export default {
  name: 'LabeledHome',
  setup(){
    const store = useStore()
    const base_url = store.state.base_url
    const InputText = ref("")
    const TextAndWord2Label = ref([])
    const show_result = ref(false)
    const bio_options = [
      {
        value: 0,
        label: 'B',
      },
      {
        value: 1,
        label: 'I',
      },
      {
        value: 2,
        label: 'O',
      },

    ]
    const label_options = [
      {
        value: 0,
        label: 'SHIP',
      },
      {
        value: 1,
        label: 'LOC',
      },
      {
        value: 2,
        label: 'TIME',
      },
      {
        value: 3,
        label: 'NUM',
      },
      {
        value: 4,
        label: 'TYPE',
      },
      {
        value: 5,
        label: 'CLS',
      },
      {
        value: 6,
        label: 'ORG',
      },
      {
        value: 7,
        label: 'ACT',
      },
      {
        value: 8,
        label: 'FOR'
      }

    ]
    const index_options = [
      {
        value: 0,
        label: 0,
      },
      {
        value: 1,
        label: 1,
      },
      {
        value: 2,
        label: 2,
      },
      {
        value: 3,
        label: 3,
      },
      {
        value: 4,
        label: 4,
      },
      {
        value: 5,
        label: 5,
      },
      {
        value: 6,
        label: 6,
      },
      {
        value: 7,
        label: 7,
      },
      {
        value: 8,
        label: '1.2',
      },
      {
        value: 9,
        label: '2.3',
      },

      {
        value: 10,
        label: '1.3',
      },
      {
        value: 11,
        label: '1.2.3',
      }
    ]
    const max_page = ref(0)
    const now_page = ref(0)
    const page_size = ref(0)
    let oldValue = 0
    const dialogVisible = ref(false)
    let now_idx_list
    const event_list = ref([])
    const checked_sent_id = ref([])
    const isAbandon = ref([])
    const eventDialogVisible = ref(false)
    const current_file = ref("")
    const file_list = ref([])
    const router = useRouter()


    const pre = () => {
      if(now_page.value > 0){
        now_page.value = now_page.value - 1
      }
    }
    const next = () => {
      if(now_page.value < max_page.value - 1){
        now_page.value = now_page.value + 1
      }
    }


    const getNowSentEvent = () => {
      axios({
        url: base_url+"/label/export/event",
        method: 'POST',
        headers:{
          Authorization : `Bearer ${sessionStorage.getItem("token")}`
        },
        data: JSON.stringify({
          word2label: TextAndWord2Label.value[now_page.value].word2label,
          sent_id: now_page.value,
        })
      }).then(res=>{
        // console.log(res.data)
        if(res.data.code == 200){
          TextAndWord2Label.value[now_page.value].word2label = res.data.word2label
          if(res.data.info ===1){
            event_list.value[now_page.value].ship2ship = res.data.sent_event.ship2ship
            event_list.value[now_page.value].ship2loc = res.data.sent_event.ship2loc
            eventDialogVisible.value = true
            checked_sent_id.value[now_page.value] = 1
          }

          else{
            alert(res.data.error_line)
          }
        }else if(res.data.code == 4001){
        //  relogin()
        }else{
          alert("请重试。")
        }

      })
    }

    const loadNowSentEvent = ()=>{
      if(checked_sent_id.value[now_page.value]===1){
        eventDialogVisible.value = true
      }
      else {
        getNowSentEvent()
      }

    }

    const fileChange = ()=>{
          axios({
            url: base_url+"/label/help/data",
            method: 'POST',
            headers:{
              Authorization : `Bearer ${sessionStorage.getItem("token")}`
            },
            data: {'filename': current_file.value}
          }).then(res=>{
            if(res.data.code == 200) {
              TextAndWord2Label.value = res.data.data
              max_page.value = Number(TextAndWord2Label.value.length)
              event_list.value = []
              for (let i = 0; i < max_page.value; i++) {
                event_list.value.push({
                  ship2ship: [],
                  ship2loc: []
                })
              }

              page_size.value = TextAndWord2Label.value[0].word2label.length
              now_page.value = 0
              now_idx_list = []
              for (let i = 0; i < max_page.value; i++) {
                now_idx_list.push([0, 0, 0, 0, 0, 0, 0, 0, 0])
              }
              show_result.value = true
              checked_sent_id.value = Array(max_page.value).fill(0)
              isAbandon.value = Array(max_page.value).fill(false)
            }else if(res.data.code == 4001){
            // relogin()
            }else{
              alert("请重试")
            }
          })
    }

    function handleCurrentChange(idx){
      now_page.value = idx - 1
    }


    const tableRowClassName = () => {
        return 'success-row'
    }



    function autoSetIndex(event,word_idx){
      oldValue = TextAndWord2Label.value[now_page.value].word2label[word_idx].label
      if(event){
        if(now_idx_list[now_page.value][oldValue] > 0){
          now_idx_list[now_page.value][oldValue] -= 1
        }
      }
      else{
        now_idx_list[now_page.value][oldValue] += 1
        TextAndWord2Label.value[now_page.value].word2label[word_idx].index = now_idx_list[now_page.value][oldValue]
        if(oldValue === 6){
          TextAndWord2Label.value[now_page.value].word2label[word_idx].ship = 1
        }
        else if(oldValue === 7){
          TextAndWord2Label.value[now_page.value].word2label[word_idx].ship = 1
          TextAndWord2Label.value[now_page.value].word2label[word_idx].loc = 1
          TextAndWord2Label.value[now_page.value].word2label[word_idx].time = 1
        }
        else if(oldValue === 8){
          TextAndWord2Label.value[now_page.value].word2label[word_idx].ship = 1
          TextAndWord2Label.value[now_page.value].word2label[word_idx].loc = 1
          TextAndWord2Label.value[now_page.value].word2label[word_idx].time = 1
          TextAndWord2Label.value[now_page.value].word2label[word_idx].act = 1
        }
      }

    }


    onMounted(()=>{
      axios({
        url: base_url+"/output/check/getLabelFileList",
        headers:{
          Authorization : `Bearer ${sessionStorage.getItem("token")}`
        },
        method: 'GET',
      }).then(
          (res)=>{
            if(res.data.code == 200){

              res.data.data.forEach((item)=>{
                file_list.value.push({value: item,label: item})
              })
              current_file.value = res.data.data[0]

              axios({
                url: base_url+"/label/help/data",
                method: 'POST',
                headers:{
                  Authorization : `Bearer ${sessionStorage.getItem("token")}`
                },
                data: {'filename': current_file.value}
              }).then(res=>{
                TextAndWord2Label.value = res.data.data
                max_page.value = Number(TextAndWord2Label.value.length)
                event_list.value = []
                for(let i=0;i<max_page.value;i++){
                  event_list.value.push({
                    ship2ship:[],
                    ship2loc:[]
                  })
                }

                page_size.value = TextAndWord2Label.value[0].word2label.length
                now_idx_list = []
                for(let i=0;i<max_page.value;i++){
                  now_idx_list.push([0, 0, 0, 0, 0, 0, 0, 0, 0])
                }
                show_result.value = true
                checked_sent_id.value =  Array(max_page.value).fill(0)
                isAbandon.value = Array(max_page.value).fill(false)
              })
            }else if(res.data.code == 4001){
              //relogin()
            }
          }
      )
    })

    const relogin = () => {
      alert("登录超时，请重新登录。")
      sessionStorage.clear()
      router.push({name:'login'})
    }


    const export_file = ()=>{
      const no_check_id = []
      checked_sent_id.value.forEach((value, index)=>{
        if(value!==1 && !isAbandon.value[index]){
          no_check_id.push(index + 1)
        }
      })
      if(no_check_id.length>0){
        alert('这些句子还没有check：'+ no_check_id)
      }
      else{
        let tmp_wor2label = []
        isAbandon.value.forEach((value, index)=>{
          if(!value){
            tmp_wor2label.push(TextAndWord2Label.value[index])
          }
        })
        axios({
          url: base_url+"/label/export/file",
          headers:{
            Authorization : `Bearer ${sessionStorage.getItem("token")}`
          },
          method: 'POST',
          data:{
            event_list: event_list.value,
            word2label_and_text_list: tmp_wor2label,
            filename: current_file.value
          }
        }).then((res)=>{
          if(res.data.code == 200){
            alert("导出成功！")
          }else if(res.data.code == 4001){
            //relogin()
          }else{
            alert("请重试")
          }
        })
      }

    }

    return{
      InputText,
      TextAndWord2Label,
      show_result,
      bio_options,
      label_options,
      index_options,
      max_page,
      now_page,
      page_size,
      pre,
      next,
      handleCurrentChange,
      tableRowClassName,
      autoSetIndex,
      dialogVisible,
      getNowSentEvent,
      eventDialogVisible,
      export_file,
      event_list,
      loadNowSentEvent,
      current_file,
      file_list,
      fileChange,
      relogin,
      isAbandon


    }
  },

  mounted() {
    window.addEventListener('beforeunload', e => this.beforeunloadHandler(e));

  },

  methods: {
    beforeunloadHandler(e) {
      this._beforeUnload_time = new Date().getTime()
      e = e || window.event
      if (e) {
        e.returnValue = '关闭提示'
      }
      return '关闭提示'
    },
  }


}
</script>

<style>
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.el-table .success-row {
  --el-table-tr-bg-color: var(--el-color-success-light-9);
}
</style>
